.logoWidthRestriction {
  height: 130px;
}


@media screen and (min-width: 550px) {
  .logoWidthRestriction {
    width: 180px;
    padding-left: 10px;
  }
}
