.centreAlign {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.headerText {
  font-family: "Avenir Bold", sans-serif;
}

.displayText {
  font-family: Avenir, sans-serif;
  width: 380px;
  text-align: center;
  padding: 10px;
  font-size: 14px;
}

.imageWidth {
  width: 100%;
  min-width: 350px;
  object-fit: cover !important;
  height: 590px !important;
}

.borderLess {
  padding-right: 0 !important;
  padding-left: 0 !important;
  color: #00B3B9 !important;
  background-color: #F9F5EA;
}

.paddingTop {
  padding-top: 180px;
  padding-bottom: 25px;
}

.button {
  border-radius: 10px;
  color: #00B3B9 !important;
  font-size: 12px;
  font-family: "Avenir Bold", sans-serif;
  padding: 8px 10px 8px 10px;
  border: solid #00B3B9 1px;
  text-decoration: none;
  text-align: center;
  width: 125px
}

@media screen and (max-width: 575px) {
  .paddingTop {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
