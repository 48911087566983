@media screen and (max-width: 1000px) {
  .blueBackground {
    padding-left: 10%;
    text-align: center;
  }
}

.appContainer {
  margin-top: 100px;
  margin-bottom: 25px;
}

.appHeroHeader {
  background-color: #FFF;
  padding-top: 15px;
  margin-top: 20px;
  margin-bottom: 15px;
  height: 55px;
  font-family: 'Lao Bold', sans-serif;
}

.appLogoBackground {
  font-family: 'Lao Bold', sans-serif;
  background-color: #F9F5EA;
}

.appHeaderText {
  margin-left: 15px;
}

.thumbnailContainer {
  margin-right: 10px;
}

.fixedComponentWidth {
  text-align: center;
  width: 100%;
}

.appHeaderImage {
  width: 70% !important;
}

.appHeaderImagePlay {
  width: 60% !important;
  padding-bottom: 10px;
}

.centreAlign {
  text-align: center;
}

.logoHeight {
  margin-left: 20px;
  height: 188px !important;
}

.border {
  text-align: center;
  padding: 15px;
  border: 8px solid;
  cursor: pointer;
  margin: 15px 15px 15px 5%;
}

.largeText {
  font-size: 18px;
}

.fullWidth {
  width: 100%;
}

.blueBackground {
  font-family: Avenir, sans-serif;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #00B3B9;
  color: #F9F5EA;
  font-size: 25px;
  text-align: center;
}

.buffer {
  background-color: #F9F5EA;
  padding: 70px 10%;
}
