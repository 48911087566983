@media screen and (min-width: 1250px) {
  .commonCol2 {
    text-align: center;
  }

  .commonCol3 {
    text-align: center;
  }
}

.centreAlign {
  background-color: #F9F5EA;
  padding-top: 50px;
}

.shareContainer {
  margin-top: 50px;
  padding-bottom: 50px;
}

.buttonRadius {
  border-radius: 50px;
  margin-right: 15px;
  color: #ED2425;
}

.bottomText {
  color: #ED2425;
}

.logoImage {
  text-align: left;
  width: 200px;
}

.linkStyle {
  color: black;
}

.paddingTop {
  padding-bottom: 100px;
}

.extraPaddingTop {
  padding-bottom: 90px;
}

.button {
  border-radius: 10px;
  color: #ED2425 !important;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  border: solid #ED2425 2px;
  text-decoration: none;
  text-align: center;
  width: 140px
}

.commonCol1 {
  text-align: center;
}

.commonCol2 {
  text-align: left;
  padding-left: 10% !important;
}

.commonCol3 {
  padding-left: 10% !important;
  text-align: left;
}

.redText {
  color: #ED2425;
  font-weight: bolder;
}
