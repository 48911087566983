.headerContainer {
  background-color: #FFF;
  text-align: center;
}

.logoImage {
  text-align: left;
  width: 200px;
}

.linkNavItem {
  font-family: "Avenir Bold", sans-serif;
  padding-right: 50px;
  text-align: center;
  margin-top: 20px;
}

.navLinks {
  margin-top: 13px !important;
  display: flex;
  font-weight: bolder;
}

.iconNavItem {
  text-align: center;
  margin-top: 50px;
}

.linkStyle {
  color: black;
}

.buttonStyle {
  border: none;
  background: white;
}

.linkStyle:hover {
  color: black;
}

.imageContainer {
  float: none;
  margin: 0 auto;
  padding-top: 20px;
  padding-right: 10px;
}

.centreAlign {
  float: none;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 30px;
}
