.boldHeading {
  font-family: "Avenir Bold", sans-serif;
  font-size: 40pt;
  text-align: center;
}

.limitedWidth {
  font-family: Avenir, sans-serif;
  font-size: 20pt;
  padding: 40px 10% 5px;
  text-align: justify;
}

.button {
  border-radius: 10px;
  color: #000 !important;
  font-size: 12px;
  font-family: "Avenir Bold", sans-serif;
  padding: 10px 20px 10px 20px;
  border: solid #000 1px;
  text-decoration: none;
  text-align: center;
  width: 130px;
  margin-bottom: 40px;
}

.buffer {
  margin-top: 10px;
  margin-bottom: 40px;
  margin-left: 60px !important;
  margin-right: 60px !important;
}

.awardPadding {
  padding-left: 5px;
  padding-right: 5px;
}

.textPadding {
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.imageWrapper {
  position: relative;
  margin: 25px;
}

.imageDescriptionLayer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(237, 36, 37, 0.9);
  color: #fff;
  visibility: visible;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 5px;
  padding-left: 35px;
}

.imageWrapper:hover .imageDescriptionLayer {
  visibility: hidden;
  opacity: 1;
}

.imageDescription {
  font-size: 9pt;
  font-family: "Avenir Bold", sans-serif;
}

@media screen and (max-width: 650px) {
  .limitedWidth {
    width: 100%;
  }
}
