.whiteTextHeader {
  font-weight: bold;
  color: #F9F5EA;
  font-size: 15px;
  margin-left: -30px;
}

.whiteTextInfo {
  color: #F9F5EA;
  font-size: 14px;
}

.allPadding {
  padding: 60px 30%;
  justify-content: space-around;
  flex-direction: column;
}

.blueBack {
  flex-grow: 0.6 !important;
  background-color: #00B3B9;
}

.contactForm {
  padding-top: 10%;
  text-align: center;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 100px;
}

.redHeader {
  color: #DA3B35;
  font-weight: bolder;
}

.subHeading {
  padding-top: 15px;
  padding-bottom: 25px;
}

.button {
  border-radius: 10px;
  color: #DA3B35 !important;
  font-size: 14px;
  padding: 5px 10px 5px 10px;
  border: solid #DA3B35 2px;
  text-decoration: none;
  text-align: center;
  width: 140px
}

@media only screen and (max-width: 1280px) {
  .blueBack {
    flex-grow: 1 !important;
    background-color: #00B3B9;
  }
}
