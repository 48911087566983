@media screen and (max-width: 650px) {
  .subPreHeader {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 21px;
  }
}

.preHeader {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ED2425;
  text-align: center;
}

.subPreHeader {
  font-family: "Avenir Next Bold", sans-serif;
  font-weight: bolder;
  font-size: 20pt;
  margin-top: 15px;
  margin-bottom: 15px;
}

.separatorImage {
  height: 5px;
  margin-bottom: 25px;
}
