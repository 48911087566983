.background {
  background-color: #F9F5EA;
  padding-top: 50px;
  padding-bottom: 20px;
  text-align: center;
  color: #ED2425;
  height: 300px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.redHeader {
  font-family: "Avenir Bold", sans-serif;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  font-size: 30px;
  font-weight: bold;
}

.redText {
  background-color: #F9F5EA;
  text-align: center;
  padding: 30px 25% 10px;
  font-size: 15px;
  font-family: "Avenir", sans-serif;
}

.hideMe {
  display: flex;
}

.imageBackground {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.imageWidth {
  object-fit: cover !important;
  height: 300px !important;
  width: 100%;
}

@media only screen and (max-width: 850px) {
  .background {
    height: 400px;
  }

  .imageWidth {
    height: 400px !important;
  }
}
