.redHeading {
  text-align: center;
  color: #ED2425;
  padding-top: 60px;
  font-size: 20px;
  font-weight: bold;
  padding-left: 10%;
  padding-right: 10%;
  font-family: "Avenir Bold", sans-serif;
}

.redSeparator {
  background-color: #ED2425;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
  color: #F9F5EA;
  justify-content: space-around;
  flex-direction: column;
}

.separatorText {
  font-family: "Avenir Bold", sans-serif;
  font-weight: bold;
  font-size: 20px;
}

.centreAlign {
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.centreAlignHeading {
  text-align: center;
  padding: 100px 15px 25px;
}

.preHeaderTop {
  margin-top: -445px;
  padding-top: -20px;
  padding-bottom: 50px;
  background-color: white;
  text-align: left;
  color: white;
  padding-right: 15%;
}

.redBackground {
  background-color: #ED2425;
}

.preHeader {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #ED2425;
  text-align: left;
  color: white;
}

.subPreHeader {
  padding-top: 20px;
  margin-bottom: 30px;
}

.boldSubHeading {
  font-family: "Avenir Bold", sans-serif;
}

.boldHeading {
  font-family: "Avenir Bold", sans-serif;
}

.separatorImage {
  height: 12px;
  margin-bottom: 25px;
}

.headerBottom {
  padding-bottom: 50px;
  padding-top: -20px;
}

.topHeader {
  padding-top: 25px;
  color: #ED2425;
  padding-bottom: 25px;
}

@media screen and (max-width: 1050px) {
  .servicesImage {
    display: none;
  }

  .preHeaderTop {
    margin-top: 50px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: red;
    text-align: left;
    color: white;
  }

  .centreAlignHeading {
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 100px;
  }

  .redBackground {
    background-color: transparent;
  }
}
