@font-face {
    font-family: 'Avenir';
    src: url('fonts/avenir.ttc');
}

@font-face {
    font-family: 'Avenir Bold';
    src: url('fonts/avenir-bold.ttc');
}

@font-face {
    font-family: 'Gill Sans';
    src: url('fonts/gill-sans.ttc');
}

@font-face {
    font-family: 'Letter Gothic Standard';
    src: url('fonts/letter-gothic-standard.otf');
}

@font-face {
    font-family: 'Letter Gothic Standard Bold';
    src: url('fonts/letter-gothic-standard-bold.otf');
}

@font-face {
    font-family: 'Verdana';
    src: url('fonts/verdana.ttf');
}

@font-face {
    font-family: 'Verdana Bold';
    src: url('fonts/verdana-bold.ttf');
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
}

body {
    font-family: Avenir, sans-serif !important;
    margin: 0;
    padding: 0;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

a:link {
    color: black;
}

a:visited {
    color: black;
}

a:hover {
    color: black;
}

a:active {
    color: black;
}

video:focus {
    outline: none;
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    justify-content: center;
}
