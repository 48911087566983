.centreAlign {
  width: 75% !important;
  font-size: 13px;
}

.divCentre {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.leftQuote {
  color: #ED2425;
  text-align: left;
  margin-bottom: -120px;
  font-size: 160pt;
  font-family: "Letter Gothic Standard", serif;
}

.rightQuote {
  text-align: right;
  margin-top: -20px;
  margin-bottom: -200px;
  color: #ED2425;
  font-size: 160pt;
  font-family: "Letter Gothic Standard", serif;
}

.textContext {
  font-family: Avenir, sans-serif;
}

.footerContext {
  font-family: "Avenir Bold", sans-serif;
  padding-top: 40px;
  font-size: 9pt;
}
